import AES from '~/plugins/AES.js'

export default function ({ route, redirect }) {
  const { query } = route;
  const str = AES.decrypt(query.email);
  const parts = str.split("&t=");
  if (parts.length === 2) {
    const timestamp = parseInt(parts[1]);
    const currentTime = new Date().getTime();
    const differenceInHours = (currentTime - timestamp) / (1000 * 60 * 60);

    if (differenceInHours > 3) {
      return redirect("/404"); // 跳转到 404 页面
    }
  } else {
    return redirect("/404"); // 跳转到 404 页面
  }
}
