export const state = () => ({
    configInfo: {},
  });
  export const mutations = {
    setConfigInfo(state, configInfo) {
      state.configInfo = configInfo;
      this.$cookies.set("config-info", configInfo, { maxAge: 2592000, path: '/' });
    },
    getConfigInfo(state) {
      state.configInfo = this.$cookies.get("config-info");
    },
  };
  export const actions = {
   
  };
  