export default function ({ route, redirect }) {
  const { path } = route;
  const parts = path.split('/').filter(Boolean);

  // 如果路径以 /index.php/products/ 开头
  if (path.startsWith('/index.php/products/')) {
    // 获取除去 /index.php/products/ 后的部分
    const newPath = path.replace('/index.php/products/', '/category/');
    return redirect(newPath);
  }

  // 如果路径以 /product/ 开头且包含多于两个部分（除了 /product/ 本身外还有分类部分）
  if (path.startsWith('/product/') && parts.length > 2) {
    // 获取最后一个部分，即产品代码
    const code = parts.pop();
    return redirect(`/product/${code}`);
  }

  if (path.startsWith('/products') && route.query.keyword) {
    const keyword = route.query.keyword;
    return redirect(`/category/searchResult?keyword=${keyword}`);
  }

  if (path === '/products' && route.query.feature === '54') {
    return redirect('/category?feature=54');
  }
}
