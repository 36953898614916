export default ({ app }) => {
  if (process.client) {
    // 只在生产环境加载百度统计脚本
    const script = document.createElement('script')
    script.src = 'https://hm.baidu.com/hm.js?59de87d4fb96946fa6fe20e34d8c014c'
    script.async = true
    script.defer = true
    document.head.appendChild(script)
  }
}
