export const state = () => ({
  loading: false,
  smImgUlIdx: 0,
});
export const mutations = {
  setLoading(state, data) {
    state.loading = data;
  },
  setSmImgUlIdx(state, data) {
    state.smImgUlIdx = data;
  },
};
