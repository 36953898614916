
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    imgShow: {
      type: Boolean,
      default: true,
    },
    imgType: {
      type: Boolean,
      default: true,
    },
    content: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      modelVisible: false,
    }
  },
  watch: {
    modelVisible(value) {
      this.$emit('update:visible', value)

      if (value) {
        document.addEventListener('keydown', this.close)
      } else {
        document.removeEventListener('keydown', this.close)
      }
    },
    visible() {
      this.modelVisible = this.visible
    },
  },
  methods: {
    notifyParent(){
      this.modelVisible = false
      this.$emit('notify-parent');
    },
    close(e) {
      if (e.keyCode === 13) {
        this.modelVisible = false
      }
    },
  },
}
