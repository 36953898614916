export const state = () => ({
  token: '',
  // email: "",
  userInfo: {},
  dialogVisible: false,
  compareList: [],
  jumpList: 'contact',
  shopInfo: {},
})
export const mutations = {
  setToken(state, token) {
    state.token = token
    this.$cookies.set('shop-token', token, { maxAge: 2592000, path: '/' })
  },
  getToken(state) {
    state.token = this.$cookies.get('shop-token')
  },
  setUserInfo(state, data) {
    state.userInfo = data
    // state.email = data.email;
    this.$cookies.set('shop-user-info', data, { maxAge: 2592000, path: '/' })
  },
  clearUserInfo(state) {
    state.userInfo = {}
    // state.email = "";
    state.token = ''
    this.$cookies.remove('shop-token')
    this.$cookies.remove('shop-user-info')
  },
  openDialog(state) {
    state.dialogVisible = true
  },
  closeDialog(state) {
    state.dialogVisible = false
  },
  setCompareList(state, data) {
    state.compareList = data
  },
  clearCompareList(state) {
    state.compareList = []
  },
  setShopInfo(state, data) {
    state.shopInfo = data
  },
}
export const actions = {
  getShopInfo(ctx, data) {
    return new Promise((resolve, reject) => {
      if (ctx.state.shopInfo && !ctx.state.shopInfo.id) {
        this.$axios
          .post(
            `/c-api/logo/info/${encodeURIComponent(
              location.hostname
              // 'kkburwoodvicg.promocatalogue.com.au'
            )}`
          )
          .then(res => {
            ctx.commit('setShopInfo', res.result)
            resolve(res.result)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      } else {
        resolve(ctx.state.shopInfo)
      }
    })
  },
  login({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      if (data) {
        this.$axios({
          url: '/au/login',
          method: 'POST',
          data,
        }).then(res => {
          commit('setToken', res.result.token)
          dispatch('getUserInfo')
          resolve(res)
        })
      }
    }).catch(() => {
      // reject(error);
    })
  },
  getUserInfo({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      this.$axios({
        url: 'au/member/detail',
        method: 'GET',
      }).then(res => {
        commit('setUserInfo', res.result)
        resolve(res)

        if (location.pathname.includes('contact')) {
          location.href = location.origin
        }
      })
    }).catch(() => {
      // reject(error);
    })
  },
  getCompareList({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post('home/compare_list', { compare_keys: 'keys' })
        .then(res => {
          commit('setCompareList', res.result)
          resolve(res)
        })
    }).catch(() => {
      // reject(error);
    })
  },
  logout({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      // 未有退出登录接口
      commit('clearUserInfo')
      resolve()
    }).catch(() => {
      // reject(error);
    })
  },
}
