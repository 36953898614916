import { Message } from 'element-ui'
export default ({ $axios, store, $cookies, redirect }) => {
  $axios.onRequest(config => {
    if (!/^\/c-api/.test(config.url)) {
      if (!/^\/api/.test(config.url)) {
        config.url = (/^\//.test(config.url) ? '/api' : '/api/') + config.url
      }
    }

    // const token = $cookies.get('shop-token')
    // if (token) {
    //   config.headers.Authorization = 'Bearer ' + token
    // }
  })

  $axios.onResponse(
    response => {
      const { data, status } = response
      if (data.code != 1 && data.msg != 'Error' && data.msg) {
        Message({
          message: data.msg,
          type: 'error',
          duration: 3 * 1000,
        })
        return Promise.reject(new Error(data.msg || 'Error'))
      } else {
        return response.data
      }
    },
    error => {
      const { data, status } = error.response
      Message({
        message: 'error:' + data.msg,
        type: 'error',
        duration: 5 * 1000,
      })
      return Promise.reject(error)
    }
  )

  $axios.onError(error => {
    const { data, status } = error.response || { data: { msg: 'unknow'}, status: 444 }
    if ([303, 304, 305].includes(status)) {
      store.dispatch('logout')
      return
    } else if (status == 500) {
      redirect('/layouts/error')
    }
    if (data.code != 200) {
      Message({
        message: '' + error.response.data.msg,
        type: 'error',
        duration: 3 * 1000,
      })
      return Promise.reject(error)
    }
  })
}
