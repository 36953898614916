// Create the script tag, set the appropriate attributes
var script = document.createElement('script')
script.src =
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyCn01lSFv-HVpYGt-BK_kYZcZ_RYij80e8&libraries=places&callback=initMap'
script.async = true

// Attach your callback function to the `window` object
window.initMap = function () {
  // JS API is loaded and available
}

// Append the 'script' element to 'head'
document.head.appendChild(script)
