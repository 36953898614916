import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1cf41b54 = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _4ddd03b0 = () => interopDefault(import('../pages/category/index.vue' /* webpackChunkName: "pages/category/index" */))
const _19cd67db = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "pages/product/index" */))
const _90f1bf80 = () => interopDefault(import('../pages/setNewPassword.vue' /* webpackChunkName: "pages/setNewPassword" */))
const _95931c7c = () => interopDefault(import('../pages/category/searchResult/index.vue' /* webpackChunkName: "pages/category/searchResult/index" */))
const _12feed5e = () => interopDefault(import('../pages/home/api.vue' /* webpackChunkName: "pages/home/api" */))
const _ff71ae40 = () => interopDefault(import('../pages/home/catalogue.vue' /* webpackChunkName: "pages/home/catalogue" */))
const _70c08a4c = () => interopDefault(import('../pages/home/compareList.vue' /* webpackChunkName: "pages/home/compareList" */))
const _5341d8d7 = () => interopDefault(import('../pages/home/contact.vue' /* webpackChunkName: "pages/home/contact" */))
const _5b236ce6 = () => interopDefault(import('../pages/home/contactPromoCollection.vue' /* webpackChunkName: "pages/home/contactPromoCollection" */))
const _030cd4b5 = () => interopDefault(import('../pages/home/contactus.vue' /* webpackChunkName: "pages/home/contactus" */))
const _474a2543 = () => interopDefault(import('../pages/home/currentFlyer/index.vue' /* webpackChunkName: "pages/home/currentFlyer/index" */))
const _be27f7d4 = () => interopDefault(import('../pages/home/downloadCenter.vue' /* webpackChunkName: "pages/home/downloadCenter" */))
const _377e88de = () => interopDefault(import('../pages/home/EDMS.vue' /* webpackChunkName: "pages/home/EDMS" */))
const _09435696 = () => interopDefault(import('../pages/home/FAQs.vue' /* webpackChunkName: "pages/home/FAQs" */))
const _13eca7f7 = () => interopDefault(import('../pages/home/loyaltyProgram.vue' /* webpackChunkName: "pages/home/loyaltyProgram" */))
const _6da624f9 = () => interopDefault(import('../pages/home/myDetail/index.vue' /* webpackChunkName: "pages/home/myDetail/index" */))
const _47a75472 = () => interopDefault(import('../pages/home/newsLetter.vue' /* webpackChunkName: "pages/home/newsLetter" */))
const _9349395e = () => interopDefault(import('../pages/home/privacyPolicy.vue' /* webpackChunkName: "pages/home/privacyPolicy" */))
const _047a2fd3 = () => interopDefault(import('../pages/home/projects.vue' /* webpackChunkName: "pages/home/projects" */))
const _23af22bc = () => interopDefault(import('../pages/home/refundReturns.vue' /* webpackChunkName: "pages/home/refundReturns" */))
const _4305add6 = () => interopDefault(import('../pages/home/termsConditions.vue' /* webpackChunkName: "pages/home/termsConditions" */))
const _005dc11c = () => interopDefault(import('../pages/home/video.vue' /* webpackChunkName: "pages/home/video" */))
const _e47041ca = () => interopDefault(import('../pages/home/myDetail/enquiryDetail.vue' /* webpackChunkName: "pages/home/myDetail/enquiryDetail" */))
const _93ac20b4 = () => interopDefault(import('../pages/home/myDetail/orderDetail.vue' /* webpackChunkName: "pages/home/myDetail/orderDetail" */))
const _06e0307c = () => interopDefault(import('../pages/home/myDetail/components/orderDetailPart.vue' /* webpackChunkName: "pages/home/myDetail/components/orderDetailPart" */))
const _30f015fb = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3c967843 = () => interopDefault(import('../pages/3D/_id.vue' /* webpackChunkName: "pages/3D/_id" */))
const _d75f92a8 = () => interopDefault(import('../pages/category/_firstCategory/index.vue' /* webpackChunkName: "pages/category/_firstCategory/index" */))
const _28ca91e0 = () => interopDefault(import('../pages/design/_id.vue' /* webpackChunkName: "pages/design/_id" */))
const _071e3270 = () => interopDefault(import('../pages/news/_newsName.vue' /* webpackChunkName: "pages/news/_newsName" */))
const _967a32f4 = () => interopDefault(import('../pages/pdf/_id.vue' /* webpackChunkName: "pages/pdf/_id" */))
const _2124fbba = () => interopDefault(import('../pages/product-builder/_code.vue' /* webpackChunkName: "pages/product-builder/_code" */))
const _4bc8c093 = () => interopDefault(import('../pages/product-record/_code.vue' /* webpackChunkName: "pages/product-record/_code" */))
const _1b9818d5 = () => interopDefault(import('../pages/product/_code.vue' /* webpackChunkName: "pages/product/_code" */))
const _c16a4230 = () => interopDefault(import('../pages/render/_id.vue' /* webpackChunkName: "pages/render/_id" */))
const _8091a464 = () => interopDefault(import('../pages/category/_firstCategory/_secondCategory/index.vue' /* webpackChunkName: "pages/category/_firstCategory/_secondCategory/index" */))
const _3ed4993d = () => interopDefault(import('../pages/category/_firstCategory/_secondCategory/_thirdCategory/index.vue' /* webpackChunkName: "pages/category/_firstCategory/_secondCategory/_thirdCategory/index" */))
const _3a1a2622 = () => interopDefault(import('../pages/orderShare/_uid/_aid/_id.vue' /* webpackChunkName: "pages/orderShare/_uid/_aid/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _1cf41b54,
    name: "aboutus"
  }, {
    path: "/category",
    component: _4ddd03b0,
    name: "category"
  }, {
    path: "/product",
    component: _19cd67db,
    name: "product"
  }, {
    path: "/setNewPassword",
    component: _90f1bf80,
    name: "setNewPassword"
  }, {
    path: "/category/searchResult",
    component: _95931c7c,
    name: "category-searchResult"
  }, {
    path: "/home/api",
    component: _12feed5e,
    name: "home-api"
  }, {
    path: "/home/catalogue",
    component: _ff71ae40,
    name: "home-catalogue"
  }, {
    path: "/home/compareList",
    component: _70c08a4c,
    name: "home-compareList"
  }, {
    path: "/home/contact",
    component: _5341d8d7,
    name: "home-contact"
  }, {
    path: "/home/contactPromoCollection",
    component: _5b236ce6,
    name: "home-contactPromoCollection"
  }, {
    path: "/home/contactus",
    component: _030cd4b5,
    name: "home-contactus"
  }, {
    path: "/home/currentFlyer",
    component: _474a2543,
    name: "home-currentFlyer"
  }, {
    path: "/home/downloadCenter",
    component: _be27f7d4,
    name: "home-downloadCenter"
  }, {
    path: "/home/EDMS",
    component: _377e88de,
    name: "home-EDMS"
  }, {
    path: "/home/FAQs",
    component: _09435696,
    name: "home-FAQs"
  }, {
    path: "/home/loyaltyProgram",
    component: _13eca7f7,
    name: "home-loyaltyProgram"
  }, {
    path: "/home/myDetail",
    component: _6da624f9,
    name: "home-myDetail"
  }, {
    path: "/home/newsLetter",
    component: _47a75472,
    name: "home-newsLetter"
  }, {
    path: "/home/privacyPolicy",
    component: _9349395e,
    name: "home-privacyPolicy"
  }, {
    path: "/home/projects",
    component: _047a2fd3,
    name: "home-projects"
  }, {
    path: "/home/refundReturns",
    component: _23af22bc,
    name: "home-refundReturns"
  }, {
    path: "/home/termsConditions",
    component: _4305add6,
    name: "home-termsConditions"
  }, {
    path: "/home/video",
    component: _005dc11c,
    name: "home-video"
  }, {
    path: "/home/myDetail/enquiryDetail",
    component: _e47041ca,
    name: "home-myDetail-enquiryDetail"
  }, {
    path: "/home/myDetail/orderDetail",
    component: _93ac20b4,
    name: "home-myDetail-orderDetail"
  }, {
    path: "/home/myDetail/components/orderDetailPart",
    component: _06e0307c,
    name: "home-myDetail-components-orderDetailPart"
  }, {
    path: "/",
    component: _30f015fb,
    name: "index"
  }, {
    path: "/3D/:id?",
    component: _3c967843,
    name: "3D-id"
  }, {
    path: "/category/:firstCategory",
    component: _d75f92a8,
    name: "category-firstCategory"
  }, {
    path: "/design/:id?",
    component: _28ca91e0,
    name: "design-id"
  }, {
    path: "/news/:newsName?",
    component: _071e3270,
    name: "news-newsName"
  }, {
    path: "/pdf/:id?",
    component: _967a32f4,
    name: "pdf-id"
  }, {
    path: "/product-builder/:code",
    component: _2124fbba,
    name: "product-builder-code"
  }, {
    path: "/product-record/:code",
    component: _4bc8c093,
    name: "product-record-code"
  }, {
    path: "/product/:code",
    component: _1b9818d5,
    name: "product-code"
  }, {
    path: "/render/:id?",
    component: _c16a4230,
    name: "render-id"
  }, {
    path: "/category/:firstCategory/:secondCategory",
    component: _8091a464,
    name: "category-firstCategory-secondCategory"
  }, {
    path: "/category/:firstCategory/:secondCategory/:thirdCategory",
    component: _3ed4993d,
    name: "category-firstCategory-secondCategory-thirdCategory"
  }, {
    path: "/orderShare/:uid?/:aid?/:id?",
    component: _3a1a2622,
    name: "orderShare-uid-aid-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
