
import { removeKeyboardKey, addKeyboardKey } from '@/utils/keyboard'
export default {
  data() {
    return {
      websitelogo: '',
    }
  },
  mounted() {
    // this.openZoho()
    // 添加监听
    window.addEventListener('keydown', addKeyboardKey)
    window.addEventListener('keyup', removeKeyboardKey)
    // 记住添加事件和卸载事件老是成对存在
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('keydown', addKeyboardKey)
      window.removeEventListener('keyup', removeKeyboardKey)
    })
  },
  methods: {
    openZoho() {
      window.$zoho = window.$zoho || {}
      // eslint-disable-next-line
      $zoho.salesiq = $zoho.salesiq || {
        widgetcode:
          'c5155e8cbfdb63f0a2b6f6f2cd50bdf2d3a9287755a068674324ed7d483e092e1baa6b2e7ee5c67d53c187e030d5c1f5',
        values: {},
        ready: function () {},
      }
      const d = document
      const s = d.createElement('script')
      s.type = 'text/javascript'
      s.id = 'zsiqscript'
      s.defer = true
      s.src = 'https://salesiq.zoho.com/widget'
      const t = d.getElementsByTagName('script')[0]
      t.parentNode.insertBefore(s, t)
      d.write("<div id='zsiqwidget'></div>")
    },
  },
}
